.baseInfo[data-v-24696af0] {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.formContentBox[data-v-24696af0] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-24696af0] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.button-container[data-v-24696af0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content[data-v-24696af0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content .img-content[data-v-24696af0] {
  width: 150px;
  height: 110px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.content .img-content .delete-icon[data-v-24696af0] {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.content .img-content img[data-v-24696af0] {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}